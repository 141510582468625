import Swiper from 'swiper/bundle';

jQuery( document ).ready(function($) {

  const syncItems = document.querySelectorAll('.sync-item')

  // menu trigger
  const menuTrigger = document.querySelector('#menu-trigger')
  const menu = document.querySelector('header .menu-main-menu-container')
  const social = document.querySelector('.social')
  if(menuTrigger){
    menuTrigger.addEventListener('click', function() {
      this.classList.toggle('active')
      social.classList.toggle('active')
      menu.classList.toggle('active')
      document.querySelector('body').classList.toggle('menu-open')
    })
  }

  // roster slider
  const swiper = new Swiper('.roster', {
    allowTouchMove: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 70,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  })

  // recent news slider
  const swiper2 = new Swiper('.recent-news', {
    allowTouchMove: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 70,
      },
    }
  })

  const pageBody = document.querySelector('body')
  if(pageBody.classList.contains('page-template-roster') || pageBody.classList.contains('page-template-imprint') || pageBody.classList.contains('page-template-composer')) {
    const artistLinks = document.querySelectorAll('.artist-link')
    const artistImages = document.querySelectorAll('.artist-image')

    artistLinks.forEach(artistLink => {
      const target = artistLink.getAttribute('data-target-artist')

      artistLink.addEventListener('mouseover', function() {
        clearActive(artistImages)
        setActive(artistImages, target)
      })
    });

    function setActive(array, theTarget){
      array.forEach(artistImage => {
        if(artistImage.getAttribute('data-artist-name') === theTarget) {
          artistImage.classList.add('active')
        }
      })
    }
  }
  
  const syncToggles = document.querySelectorAll('.sync-toggle')
  const syncOptions = document.querySelectorAll('.sync-option')
  syncToggles.forEach(syncToggle => {
    syncToggle.addEventListener('click', function() {
      const target = this.getAttribute('data-sync-target')
      clearActive(syncToggles)
      clearActive(syncOptions)
      this.classList.add('active')
      document.querySelector(`#${target}`).classList.add('active')
    })
  })

  function clearActive(array){
    array.forEach(item => {
      item.classList.remove('active')
    })
  }

  // syncs slider
  const swiper3 = new Swiper('.sync-slider', {
    allowTouchMove: false,
    slidesPerView: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    loop: true,
    observeSlideChildren: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    }
  })

  swiper3.on('slideChange', function () {
    syncItems.forEach(syncItem => {
      syncItem.classList.remove('active')
      if (syncItem.querySelector('iframe')){
        const syncSrc = syncItem.querySelector('iframe').getAttribute('src')  
        syncItem.querySelector('iframe').setAttribute('src', syncSrc)
      }
    })
  });

  // featured artists slider
  const swiper4 = new Swiper('.featured-artists-slider', {
    allowTouchMove: true,
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 2000,
    },
    speed: 750,
    pauseOnMouseEnter: true,
  })

  
  syncItems.forEach(syncItem => {
    syncItem.addEventListener('click', function(e) {
      e.preventDefault()
      clearActive(syncItems)
      this.classList.add('active')
    })
  })

  function clearActive(array){
    array.forEach(item => {
      item.classList.remove('active')
    })
  }

  //add class when scroll
  $(window).scroll(function() {
    if ($(this).scrollTop() > 1){  
      $('header').addClass("sticky");
    }
    else{
      $('header').removeClass("sticky");
    }
  });
});